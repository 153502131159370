const appendConfigurationsToFormData = (
	formData,
	configurationsData,
	isEdit = false,
	isDublicate = false
) => {
	console.log({ isEdit }, { isDublicate });
	const destination = isEdit
		? 'configurationsUpdate.newConfigurations'
		: 'configurations';
	for (let i = 0; i < configurationsData.length; i++) {
		if (configurationsData[i].id) {
			// eslint-disable-next-line no-continue
			continue;
		}
		formData.append(
			`${destination}[${i}].colorCode`,
			configurationsData[i].colorCode
		);
		formData.append(
			`${destination}[${i}].mainImage`,
			configurationsData[i].mainImage
		);

		formData.append(
			`${destination}[${i}].gallery`,
			configurationsData[i].gallery
		);

		// eslint-disable-next-line no-plusplus
		for (let g = 0; g < configurationsData[i].gallery.length; ++g) {
			formData.append(
				`${destination}[${i}].gallery`,
				configurationsData[i].gallery[g]
			);
		}
		const { options } = configurationsData[i];

		// eslint-disable-next-line no-plusplus
		for (let j = 0; j < options.length; j++) {
			formData.append(
				`${destination}[${i}].options[${j}].upcOrSku`,
				options[j].upcOrSku
			);
			formData.append(
				`${destination}[${i}].options[${j}].count`,
				options[j].count
			);
			formData.append(
				`${destination}[${i}].options[${j}].sizeOptionGeo`,
				options[j].sizeOptionGeo
			);
			formData.append(
				`${destination}[${i}].options[${j}].sizeOptionEng`,
				options[j].sizeOptionEng
			);
		}
	}
};

export default appendConfigurationsToFormData;
