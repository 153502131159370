const appendUpdateConfigurations = (form, cu, isApproved = false) => {
  const formData = form || new FormData();
  const editedProductConfigType = isApproved ? "editedProductConfigurations" : "editedReviewProductConfigurations";

  if (cu.deletedConfigurations.length) {
    cu.deletedConfigurations.forEach((e) => {
      formData.append(`configurationsUpdate.deletedConfigurations`, e);
    });
  }
  if (cu.newConfigurations.length) {
    cu.newConfigurations.forEach((e, index) => {
      formData.append(`newConfigurations[${index}].colorCode`, e.colorCode);
      formData.append(`newConfigurations[${index}].mainImage`, e.mainImage);
      e.gallery.forEach((image) => {
        formData.append(`newConfigurations[${index}].gallery`, image);
      });
      e.options.forEach((option, optionIndex) => {
        formData.append(`newConfigurations[${index}].options[${optionIndex}].count`, option.count);
        formData.append(`newConfigurations[${index}].options[${optionIndex}].sizeOptionEng`, option.sizeOptionEng);
        formData.append(`newConfigurations[${index}].options[${optionIndex}].sizeOptionGeo`, option.sizeOptionGeo);
        formData.append(`newConfigurations[${index}].options[${optionIndex}].upcOrSku`, option.upcOrSku);
      });
    });
  }
  if (cu[editedProductConfigType].length) {
    cu[editedProductConfigType].forEach((e, index) => {
      formData.append(`configurationsUpdate.${editedProductConfigType}[${index}].configurationId`, e.configurationId);
      formData.append(
        `configurationsUpdate.${editedProductConfigType}[${index}].mainImageUpdate.isPreviousDeleted`,
        e.mainImageUpdate.isPreviousDeleted
      );
      formData.append(
        `configurationsUpdate.${editedProductConfigType}[${index}].mainImageUpdate.newImage`,
        e.mainImageUpdate.newImage
      );

      e.galleryUpdate.DeletedImages.forEach((deletedImage) => {
        formData.append(
          `configurationsUpdate.${editedProductConfigType}[${index}].galleryUpdate.DeletedImages`,
          deletedImage
        );
      });
      e.galleryUpdate.NewImages.forEach((newImage) => {
        formData.append(`configurationsUpdate.${editedProductConfigType}[${index}].galleryUpdate.newImages`, newImage);
      });
      e.options.forEach((option, optionIndex) => {
        formData.append(
          `configurationsUpdate.${editedProductConfigType}[${index}].options[${optionIndex}].count`,
          option.count
        );
        formData.append(
          `configurationsUpdate.${editedProductConfigType}[${index}].options[${optionIndex}].sizeOptionEng`,
          option.sizeOptionEng
        );
        formData.append(
          `configurationsUpdate.${editedProductConfigType}[${index}].options[${optionIndex}].sizeOptionGeo`,
          option.sizeOptionGeo
        );
        formData.append(
          `configurationsUpdate.${editedProductConfigType}[${index}].options[${optionIndex}].upcOrSku`,
          option.upcOrSku
        );
      });
      if (e.deletedOptions.length) {
        e.deletedOptions.forEach((deletedOptionId) => {
          formData.append(`configurationsUpdate.${editedProductConfigType}[${index}].deletedOptions`, deletedOptionId);
        });
      }
    });
  }
};
export default appendUpdateConfigurations;
